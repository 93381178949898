import '../styles/RightComponent.css';

function RightComponent(props) {
  const partes = props.text.split('{negrito}');

  const textoRenderizado = partes.map((parte, index) => (
      index % 2 === 1 ? <strong key={index}>{parte}</strong> : parte
  ));  

  return (
    <div class="right-content">
      <div class="text-content-right">
        <h3>{props.title}</h3>
        <p>{textoRenderizado}</p>
      </div>
      <img src={props.img} alt={props.alt} />
    </div>
  );
}

export default RightComponent;
