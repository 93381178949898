import Button from './Button';

import photo from '../img/quem-e-valomax.png';

import '../styles/AboutUsContent.css';

function AboutUsContent() {
    return (
        <section class="about-us-content">
          <h1>Mas quem é a Valomax?</h1>
          <p>A Valomax surge com um sonho que começou em 2013. Nossa fundadora, que soma <strong>mais de 30 anos de experiência bancária</strong>, foi a força motriz por trás desse empreendimento.</p>
          <div class="about-us-text-content">
            <div class="about-us-text">
              <p>Nossos valores são o nosso norte. Obviamente, sempre seguindo todas as normas éticas e legais em nosso trabalho, a honestidade é uma regra básica para nós, nos pautamos diariamente em:</p>
              <h3>Transparência</h3>
              <p>Comprometemo-nos a ser transparentes em todas as nossas interações, fornecendo informações claras e honestas aos nossos clientes. <strong>Queremos que você se sinta seguro e informado em todas as etapas do processo.</strong></p>
              <h3>Agilidade</h3>
              <p>Sabemos que o <strong>tempo é precioso</strong>, e é por isso que nos comprometemos a ser ágeis em nossas ações. Estamos sempre prontos para responder às suas necessidades de forma rápida e eficaz.</p>
              <h3>Transparência</h3>
              <p>Nos esforçamos para superar as expectativas, oferecendo serviços de alta qualidade e soluções que realmente fazem a diferença na vida dos nossos clientes. <strong>A excelência é o nosso padrão.</strong></p>
            </div>
            <img class="about-us-img" src={photo} alt="Foto das duas sócias, a esquerda, uma mulher jovem de cabelo castanho acobreado com mechas loiras e olhos castanhos. À direita, uma mulher mais velha com cabelo cacheado loiro escuro e olhos verdes."/>
          </div>
          <h3>Ousadia</h3>
          <p>Estamos dispostos a <strong>enfrentar desafios</strong> de frente, pensar fora da caixa e <strong>encontrar maneiras criativas de atender às necessidades dos nossos clientes</strong>. A ousadia nos motiva a crescer e evoluir continuamente.</p>
          <Button text="entrar em contato" />
        </section>
    );
}

export default AboutUsContent;
