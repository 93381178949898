import Button from './Button';

import '../styles/FrontPartnership.css';

function FrontPartnership() {
    return (
        <section class="content front-partnership">
          <div class="front-partnership-text">
            <h2>Seja nosso parceiro!</h2>
            <p>Seja parceiro da família Valomax: aqui, acreditamos que podemos construir uma relação de sucesso mútuo, repleta de oportunidades financeiras e vantagens únicas.</p>
            <div class="button">
              <Button text="SAIBA MAIS"/>
            </div>
          </div>
          <div class="partnership-img" alt="Imagem de várias mãos unidas, dando a ideia de trabalho em equipe."/>
        </section>
    );
}

export default FrontPartnership;