import Button from './Button';

import logo from '../img/logo.png';

import '../styles/FrontContent.css';

function FrontContent() {
    return (
    <section class="content front-content">
        <div class="front-content-who-is-valomax">
            <img src={logo} alt="Imagem com a logo da Valomax, fundo desforme azul e amarelo e letras maiúsculas V e X." />
            <h3>Quem é a Valomax?</h3>
        </div>
        <div class="front-content-services">
            <div class="front-content-services-img" />
            <div class="front-content-services-text">
            <h3>Nossos serviços</h3>
            <p>Desde 2013 entregando soluções com qualidade e satisfação aos nossos clientes. Veja aqui como vamos ajudar você!</p>
            <Button text="SAIBA MAIS" />
      </div>
    </div>
  </section>
  );
}

export default FrontContent;