import '../styles/LeftComponent.css';

function LeftComponent(props) {
    const partes = props.text.split('{negrito}');

    const textoRenderizado = partes.map((parte, index) => (
        index % 2 === 1 ? <strong key={index}>{parte}</strong> : parte
    ));

    return (
        <div class="left-content">
            <img src={props.img} alt={props.alt} />
            <div class="text-content-left">
                <h3>{props.title}</h3>
                <p>{textoRenderizado}</p>
            </div>
        </div>
    );
}

export default LeftComponent;
