import Header from './components/Header';
import Button from './components/Button';
import FrontPartnership from './components/FrontPartnership';
import FrontContent from './components/FrontContent';
import RightComponent from './components/RightComponent';
import LeftComponent from './components/LeftComponent';
import AboutUsContent from './components/AboutUsContent';

import finance from './img/ganhos-atraentes-icon.png';
import experience from './img/experiencia-mercado-icon.png';
import agil from './img/agilidade-eficiencia-icon.png';
import costumer from './img/atendimento-personalizado-icon.png';
import indication from './img/indicacao-icon.png';
import credit from './img/credito-icon.png';
import desp from './img/despachante-icon.png';
import security from './img/seguros-icon.png';

import './App.css';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <main>
      <Header/>
      <section class="main-body-section">
        <FrontPartnership />
        <hr/>
        <FrontContent />
        <hr />
        <section class="partnership-content">
          <h1>Confira as vantagens de ser nosso parceiro!</h1>
          <RightComponent 
            title="Ganhos Financeiros Atraentes" 
            text ="Parceria conosco é sinônimo de ganhos expressivos! Venha elevar seus ganhos com uma atraente comissão sobre financiamento." 
            img={finance} 
            alt="Ícone redondo, azul, com um cifrão amarelo centralizado." />
          <LeftComponent
            title="Mais de 10 Anos de Experiência no Mercado"
            text="Com mais de 10 anos no mercado imobiliário e 30 anos de expertise bancária, construímos relacionamentos duradouros e um profundo conhecimento que são fundamentais para enfrentar os desafios que você nos trouxer."
            img={experience}
            alt="Ícone redondo, azul, com uma rede de pessoas interligadas em amarelo e centralizado." />
          <RightComponent
            title="Agilidade e Eficiência"
            text="A agilidade é uma das marcas registradas da Valomax. Despachamos processos com velocidade e precisão, eliminando as dores de cabeça burocráticas e permitindo que você feche negócios mais rapidamente."
            img={agil}
            alt="Ícone redondo, azul, com um cifrão amarelo centralizado." />
          <LeftComponent
            title="Atendimento Personalizado"
            text="Cada cliente é único, e tratamos cada caso de forma personalizada. Nossa equipe dedicada está pronta para atender às necessidades específicas de seus clientes, proporcionando um atendimento de alta qualidade e soluções sob medida."
            img={costumer}
            alt="Ícone redondo, azul, com duas mãos dando um aperto de mão, na cor amarela e centralizado." />
          <h2>Nós vamos adorar colaborar com você, vem com a gente?</h2>  
          <Button text="entrar em contato" />
          <hr />
        </section>
        <AboutUsContent />
        <hr />
        <section class="services">
          <h1>E como a Valomax pode me ajudar hoje?</h1>
          <RightComponent 
            title="Te indicamos um profissional parceiro para te ajudar a encontrar o imóvel ideal para você!" 
            text="Nossa abordagem inclui não apenas o compromisso com nossos clientes, mas também o apoio de uma rede de profissionais parceiros altamente qualificados. Indicamos um profissional parceiro dedicado a entender suas necessidades e desejos, ajudando você a descobrir o lar dos seus sonhos."
            img={indication} 
            alt="Ícone redondo, amarelo, com duas mulheres desenhadas ao meio, uma entregando uma chave à outra." />
          <LeftComponent
            title="Buscamos, juntos, a melhor solução de crédito do mercado de acordo com as suas necessidades!"
            text="Trabalhamos apenas com os bancos mais sólidos do mercado, trazendo a confiabilidade dos 'bancões' para o atendimento personalizado da Valomax. Aqui, a escolha é sua, seja um {negrito}financiamento habitacional{negrito}, uma {negrito}carta de consórcio{negrito} ou um {negrito}empréstimo consignado{negrito}, tudo personalizado de acordo com as suas necessidades. Queremos garantir que você tenha a segurança do apoio financeiro que merece, de forma aconchegante e pessoal."
            img={credit}
            alt="Ícone redondo, amarelo, com uma mulher desenhada ao centro, com uma calculadora e notas ao fundo." />
          <RightComponent 
            title="Resolvemos todas as burocracias para te deixar livre para se preocupar com o que, realmente, importa!" 
            text="Aqui, fazemos questão de cuidar de todas as {negrito}burocracias bancárias e cartorárias{negrito} para que você possa se  concentrar nas coisas que realmente importam. Nossos {negrito}serviços de despachante{negrito} abrangem de tudo, {negrito}financiamentos habitacionais{negrito}, {negrito}contemplação de cartas de consórcio{negrito}, {negrito}compra e venda de imóveis{negrito}, {negrito}inventários{negrito} e muito mais. Assumimos o trabalho árduo, os documentos e prazos, para que você tenha tempo e tranquilidade para se dedicar a você e sua família."
            img={desp} 
            alt="Ícone redondo, amarelo, com alguns papéis brancos e simbolos em azul." />
          <LeftComponent
            title="Oferecemos as melhores opções de seguros para proteger você, sua família e seus bens de eventuais problemas!"
            text="Estamos aqui para garantir a segurança do que é mais importante para você. Oferecemos as melhores opções de {negrito}seguros da Caixa Econômica Federal{negrito}, para proteger você, sua família e seus bens de quaisquer imprevistos. A sua tranquilidade é a nossa prioridade, e estamos prontos para ajudar a construir um futuro mais seguro para você e seus entes queridos."
            img={security}
            alt="Ícone redondo, amarelo, com uma mulher e um homem desenhados ao centro, sentados em uma mão azul e com um computador." />
          <h2>A Valomax tem uma solução para você, basta você...</h2>
          <Button text="entrar em contato" />
        </section>
        <hr />
        <Contact />
      </section>
      <Footer />
    </main>
  );
}

export default App;
