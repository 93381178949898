import '../styles/Footer.css';

function Footer() {
    return (
        <footer>
            <p>Design e desenvolvimento por Laura Ramos. Todos os direitos reservados à Valomax.</p>
        </footer>
    );
}

export default Footer;