import location from '../img/localizacao-icon.png';
import phone from '../img/telefone-icon.png';
import email from '../img/email-icon.png';
import instagram from '../img/instagram-icon.png';

import '../styles/Contact.css';

function Contact() {
    return (
        <section>
          <h1>Você pode nos encontrar em...</h1>
          <div class="columns">
            <div>
            <div class="contact-item">
                <img src={location} alt="Ícone de localização." />
                <p>Rua dos Carijós, 424, sala 2312 - Centro<br/>Belo Horizonte/MG</p>
            </div>
            <div class="contact-item">
                <img src={phone} alt="Ícone de telefone" />
                <p>(31) 9 9394-0589</p>
            </div>
            </div>
            <div>
            <div class="contact-item">
                <img src={email} alt="Ícone de e-mail." />
                <p>contato@valomax.com.br</p>
            </div>
            <div class="contact-item">
                <img src={instagram} alt="Ícone do Instagram." />
                <p>@valomax.bh</p>
            </div>
            </div>
          </div>
        </section>
    );
}

export default Contact;
