import '../styles/Button.css';

function Button(props) {
    return (
        <a href='https://api.whatsapp.com/send?phone=+5531993940589&text=Oi%21+Gostaria+de+saber+mais+sobre+os+servi%C3%A7os+da+Valomax.' target='_blank' rel='noreferrer'>
            <div class="button">
                <button>{props.text}</button>
            </div>
        </a>
    )
}

export default Button;