import logo from '../img/logo-sem-fundo.png';
import '../styles/Header.css';

function Header() {
    return (
        <header>
            <img src={logo} alt="Logo Valomax, desenho disforme amarelo e verde, com VX escrito dentro em fonte arredondada."/>
            <div class="header-text"></div>
        </header>
    );
}

export default Header;